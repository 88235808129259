<template>
  <section id="services">
    <Services></Services>
    <Faq class="mt-4"></Faq>
  </section>
</template>

<script>
const Services = () => import("@/components/Services");
const Faq = () => import("@/components/Faq");
export default {
  components: {
    Services,
    Faq
  },
  mounted() {
    document.title = "Tomalex - Services";
  }
};
</script>
<style>
#services {
  padding: 100px 0 120px 0;
}
</style>
